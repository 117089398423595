import moment from "moment";

const MODULE_CODE = 'liff_booking'

export default {
  methods: {
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    init() {
      let filter = this.getModuleConfig('filter_by_date');
      if (filter) {
        if (filter.show_option && !filter.show_calendar) {
          this.daysDiff = filter.options[0].key;
          this.endAt = moment().format("YYYY-MM-DD");

          if (this.$route.query.startAt) {
            this.daysDiff = moment(this.$route.query.endAt).diff(
              this.$route.query.startAt,
              "days"
            );
          }

          this.startAt =
            this.$route.query.startAt ||
            moment().subtract(this.daysDiff, "days").format("YYYY-MM-DD");
        }
        if (filter.show_option) {
          this.endAt = this.$route.query.endAt || moment().format("YYYY-MM-DD");
          this.startAt =
            this.$route.query.startAt ||
            moment()
              .subtract(filter.options[0].key, "days")
              .format("YYYY-MM-DD");

          this.daysDiff =
            this.$route.query.endAt && this.$route.query.startAt
              ? moment(this.$route.query.endAt).diff(
                  this.$route.query.startAt,
                  "days"
                )
              : filter.options[0].key;
        }
      } else {
        this.startAt = this.$route.query.startAt || moment().subtract(1, "year").format("YYYY-MM-DD")
        this.endAt = this.$route.query.endAt || moment().add(1, 'year').format("YYYY-MM-DD")
      }
    },
    setQuery(){
      if (!this.$route.query.startAt || !this.$route.query.endAt) {
        this.$router.push({
          query: {
            startAt: this.startAt,
            endAt: this.endAt
          }
        });
      }
    }
  }
}
